import React from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import { useSearchParams } from 'react-router-dom'

const resolveSubtitle = (hasPerioFlag) => {
  return hasPerioFlag
    ? 'Your Care Team will text you shortly.'
    : 'We look forward to seeing you.'
}

const ConfirmationSuccessPage = () => {
  const [searchParams] = useSearchParams()

  return (
    <ConfirmationPage
      title="You're all set!"
      subtitle={
        resolveSubtitle(!!searchParams.get('perio'))
      }
    />
  )
}

export default ConfirmationSuccessPage
