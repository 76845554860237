import React, { useEffect } from 'react'
import { TextHeader, Logo, Button, Terms, Divider, DividerOrientation } from '@wallyhealth/web-core'
import { times } from 'ramda'
import intelService from '../../../services/intelService'
import styles from './index.module.css'

const PAGE_NAME = 'JoinStartPage2'

const FEATURES = [
  'Unlimited cleanings',
  'Unlimited whitening',
  'Checkups, 3D scans & X-rays',
  'One-click booking',
  'Dedicated care team',
  'Virtual follow-ups'
]

const SmartMouthStrip = () => (
  <div className={styles.smart_mouth}>
    <ul className={styles.smart_mouth_list}>
      {times((index) => (
        <li key={`smart-mouth-list-item-${index}`}>
          Be a smart mouth
        </li>
      ), 20)}
    </ul>
  </div>
)

const MainSection = ({ onStart }) => {
  const onClick = async () => {
    intelService.recordClick({
      clickName: 'GetStarted',
      viewName: PAGE_NAME
    })
    onStart()
  }

  return (
    <div className={styles.main}>
      <div className={styles.main_body}>
        <TextHeader
          className={styles.header}
          title="One simple membership."
        />

        <Button
          className={styles.main_button}
          onClick={onClick}
          label="Get started"
        />

        <Terms
          className={styles.main_terms}
        />
      </div>
    </div>
  )
}

const SupportingSection = () => (
  <div className={styles.supporting}>
    <ul className={styles.features}>
      {FEATURES.map((feature, index) =>
        <li
          key={`plan-feature-${index}`}
          className={styles.feature}>
          {feature}
        </li>
      )}
    </ul>
  </div>
)

const JoinStartPage = ({ onStart }) => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  return (
    <div className={styles.container}>
      <Divider
        className={styles.divider_horizontal}
        orientation={DividerOrientation.HORIZONTAL}
      />
      <Logo
        className={styles.logo}
      />
      <MainSection
        onStart={onStart}
      />
      <Divider
        className={styles.divider_vertical}
        orientation={DividerOrientation.VERTICAL}
      />
      <SupportingSection/>
      <SmartMouthStrip />
    </div>
  )
}

export default JoinStartPage
