export const isValidPhoneNumber = (phone) => {
  /*  This function is based on the validator package's isMobilePhone function. Rather than using that
      function, we decided to create our own version of it because we didn't want to be as strict with
      the phone number pattern. In particular, the validator library does not allow the area code and
      the central office code (i.e. the three digit segment that follows the area code) to start with
      a 1 or 0. This was too restrictive for us because if these rules change, then we don't want to
      get left behind. */
  if (!phone) {
    return false
  }
  const usPhonePattern = /^((\+1|1)?( |-)?)?(\([0-9]{3}\)|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/
  return usPhonePattern.test(phone)
}
