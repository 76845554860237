import { useQuery } from '@tanstack/react-query'
import { fetchFulfilledSchedulingSlotIds } from '../api'

export const useFetchFulfilledSlots = ({ linkId }) => {
  const { data: fulfilledSlotIds = [] } = useQuery({
    queryKey: ['fetchFulfilledSlotIds', linkId],
    queryFn: () => fetchFulfilledSchedulingSlotIds({ linkId }),
    refetchInterval: 10000
  })

  return {
    fulfilledSlotIds
  }
}
