import { StyledErrorPage } from '@wallyhealth/web-core'
import { useStandbyScheduleSlots } from './useStandbyScheduleSlots.hook'
import StandbyPage from './schedule'
import useQuery from 'hooks/useQuery'

const StandbyScheduleFlow = () => {
  const query = useQuery()
  const linkId = query.get('id')

  const {
    isSchedulingLinkInvalid,
    isSchedulingLinkUsed,
    isSchedulingLinkDisabled,
    isSchedulingLinkExpired
  } = useStandbyScheduleSlots({ linkId })

  if (isSchedulingLinkUsed) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="Looks like this link has been used already."
      />
    )
  }

  if (isSchedulingLinkExpired) {
    return (
      <StyledErrorPage
        title="All spoken for!"
        description="There's no more availability for tomorrow but we'll send another message when more become available!"
      />
    )
  }

  if (isSchedulingLinkDisabled) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="Looks like this link has been disabled."
      />
    )
  }

  if (!linkId || isSchedulingLinkInvalid) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="That looks like an invalid link."
      />
    )
  }

  return (
    <StandbyPage linkId={linkId} />
  )
}

export default StandbyScheduleFlow
