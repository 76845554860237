import React, { useEffect, useState } from 'react'
import { ExpressCheckoutElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useNotifications } from '@wallyhealth/web-core'
import classes from 'classnames'
import { PlanType } from '../../shared'
import config from '../../../../config'
import styles from './index.module.css'
import { captureError } from 'services/monitoringService'

const LOAD_TIMEOUT_IN_MS = 3000

const ExpressPayOption = ({ className, totalCostInCents, planType, recordPaymentClick, prepSubscription, onInit }) => {
  const notifications = useNotifications()
  const stripe = useStripe()
  const elements = useElements()

  const [isInitialized, setIsInitialized] = useState(false)
  const [timer, setTimer] = useState(null)

  const handleInit = ({ isGooglePayAvailable, isApplePayAvailable }) => {
    setIsInitialized(true)
    onInit({ isGooglePayAvailable, isApplePayAvailable })
  }

  const initializeWithoutExpressOptions = () => {
    handleInit({
      isGooglePayAvailable: false,
      isApplePayAvailable: false
    })
  }

  const createTimer = () => {
    return setTimeout(() => {
      initializeWithoutExpressOptions()
    }, LOAD_TIMEOUT_IN_MS)
  }
  const setUpTimer = () => {
    const timer = createTimer()
    setTimer(timer)
    return () => {
      clearTimeout(timer)
    }
  }
  useEffect(() => {
    return setUpTimer()
  }, [])

  useEffect(() => {
    if (isInitialized) {
      clearTimeout(timer)
    }
  }, [isInitialized])

  const onConfirm = async (event) => {
    if (!stripe) {
      return
    }

    recordPaymentClick({ clickName: 'ExpressPayClick' })

    const { error: submitError } = await elements.submit()
    if (submitError) {
      notifications.error(submitError.message)
      return
    }

    const { clientSecret } = await prepSubscription()
    if (!clientSecret) {
      return
    }

    const { error: confirmError } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${config.APP_URL}/welcome?amount=${totalCostInCents}`
      }
    })

    if (confirmError) {
      notifications.error(confirmError.message)
    }
  }

  const resolveAppleInterval = () => {
    return (planType === PlanType.ANNUAL)
      ? 'year'
      : 'month'
  }

  const resolveMembershipName = () => {
    return (planType === PlanType.ANNUAL)
      ? 'Annual Membership'
      : 'Monthly Membership'
  }

  const onElementReady = (event) => {
    const {
      availablePaymentMethods: {
        googlePay = false,
        applePay = false
      } = {}
    } = event

    handleInit({
      isGooglePayAvailable: googlePay,
      isApplePayAvailable: applePay
    })
  }

  const onElementError = (event) => {
    const {
      error: {
        message = 'An unknown error occurred',
        code = 'unknown_error'
      } = {}
    } = event

    captureError({ error: new Error(`${code}: ${message}`) })

    initializeWithoutExpressOptions()
  }

  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: false,
      phoneNumberRequired: false,
      applePay: {
        recurringPaymentRequest: {
          paymentDescription: 'Wally Health',
          managementURL: 'https://www.carebywally.com',
          regularBilling: {
            amount: totalCostInCents,
            label: resolveMembershipName(),
            recurringPaymentIntervalUnit: resolveAppleInterval(),
            recurringPaymentIntervalCount: 1
          }
        }
      }
    }
    resolve(options)
  }

  return (
    <div className={classes(styles.container, className)}>
      <ExpressCheckoutElement
        onConfirm={onConfirm}
        onClick={onClick}
        onReady={onElementReady}
        onLoadError={onElementError}
        wallet={{
          applePay: 'always',
          googlePay: 'always'
        }}
        options={{
          buttonHeight: (window.innerWidth > 768) ? 54 : 45
        }}
      />
    </div>
  )
}

export default ExpressPayOption
