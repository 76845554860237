import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const initMonitor = () => {
  Sentry.init({
    dsn: 'https://e34f0bf2d6db4696a4943b600c6eb91e@o4504599944364032.ingest.sentry.io/4504599983489024',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: window.__ENV
  })
}

export const captureError = ({ error }) => {
  Sentry.captureException(error)
}
