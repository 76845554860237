import React, { useState } from 'react'
import { FullPage, TextHeader, Button, ButtonType, ButtonSize } from '@wallyhealth/web-core'
import moment from 'moment'
import CancellationModal from '../CancelationModal'
import styles from './index.module.css'

const Subtitle = ({ appointmentTimeLocal, studioAddress }) => {
  const formattedTime = moment(appointmentTimeLocal).format('h:mm A [on] MMM D')

  return (
    <p className={styles.info}>
      As a reminder,<br className={styles.break}/> your appointment is scheduled for <br/><span className={styles.bold}>{formattedTime}</span> at <span className={styles.bold}>{studioAddress}</span>
    </p>
  )
}

const ConfirmationPage = ({ confirmation, onConfirm, isConfirming, onReschedule, isRescheduling }) => {
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false)

  const confirmAppointment = async () => {
    await onConfirm()
  }

  const rescheduleAppointment = async () => {
    await onReschedule()
  }

  const resolveDayName = () => {
    return moment(confirmation.appointmentTimeLocal).format('dddd')
  }

  return (
    <FullPage bodyClassName={styles.container}>

      <TextHeader
        title={`See you ${resolveDayName()}?`}
        titleClassName={styles.title}
        subtitle={
          <Subtitle
            appointmentTimeLocal={confirmation.appointmentTimeLocal}
            studioAddress={confirmation.studioAddress}
          />
        }
        subtitleClassName={styles.subtitle}
      />

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          type={ButtonType.DEFAULT}
          size={ButtonSize.SMALL}
          label="Yup"
          onClick={confirmAppointment}
          isLoading={isConfirming}
        />
        <Button
          className={styles.button}
          type={ButtonType.TERNARY}
          size={ButtonSize.SMALL}
          label="No, reschedule"
          onClick={() => setIsCancellationModalOpen(true)}
          isLoading={isRescheduling}
        />
      </div>

      <CancellationModal
        isOpen={isCancellationModalOpen}
        onClosed={() => setIsCancellationModalOpen(false)}
        onContinue={rescheduleAppointment}
        appointmentTypes={confirmation.appointmentTypes}
      />

    </FullPage>
  )
}

export default ConfirmationPage
