import styles from './index.module.css'

const WhiteningVideo = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <iframe
        src="https://player.vimeo.com/video/770193660?h=315a48cd14"
        frameBorder="0"
        allow="autoplay; picture-in-picture"
        style={{
          position: 'absolute',
          left: '-25px',
          top: '-95px',
          height: '450px',
          margin: 0
        }}>
      </iframe>
    </div>
  </div>
)

export default WhiteningVideo
