import { Button } from '@wallyhealth/web-core'
import classes from 'classnames'
import styles from './index.module.css'

const ErrorTreatment = ({ className, onTryAgainClick, isLoading }) => (
  <div className={classes(styles.container, className)}>
    <span className={styles.text}>
      Sorry, we were unable to retrieve your membership options.
    </span>

    <Button
      label="Try again"
      className={styles.button}
      isLoading={isLoading}
      onClick={onTryAgainClick}
    />
  </div>
)

export default ErrorTreatment
