import { ActionStepper, ActionStepperSize } from '@wallyhealth/web-core'
import styles from './index.module.css'
import items from './items.json'

const StepsSection = () => (
  <div className={styles.container}>
    <div className={styles.body}>
      <ActionStepper
        className={styles.steps}
        descriptionClassName={styles.description}
        stepperClassName={styles.stepper}
        title="TRY-IN STEP"
        items={items}
        size={ActionStepperSize.LARGE}
      />
    </div>
  </div>
)

export default StepsSection
