import Markdown from 'react-markdown'

const gunkAnswer = `
Time for a cleanse. We recommend giving your guard a little extra TLC once a month:
1. Create a solution of water and vinegar (1:1 ratio) and place it in a glass or ceramic bowl. Make sure there's enough that it will cover your night guard.
2. Let your guard soak for 30 min.
3. After 30 min, rinse your night guard and the bowl out with lukewarm water.
4. Pour hydrogen peroxide into the bowl (until it covers the guard).
5. Soak the guard in the hydrogen peroxide for 30 minutes.
6. After 30 min, rinse your guard with lukewarm water and let it dry on a flat surface.
7. Wash your case out with dish soap. Make sure your case is completely clear and dry before storing your guard in it again.
`

const faqs = [
  {
    question: 'Can I bring my guard with me to my appointment?',
    answer: 'Yes! We’ll clean it up for you and check the fit.'
  },
  {
    question: 'How often should I wear my guard?',
    answer: 'Wear your guard nightly while you sleep. If you have a hard time adjusting, start out wearing it a few hours each day and work up to wearing it for the full night.'
  },
  {
    question: 'My new guard feels tight, what should I do?',
    answer: 'Soak it in warm (not hot) water for 1 minute to let it soften, then place it in your mouth using your fingers to guide it onto your teeth.'
  },
  {
    question: 'How do I know my guard fits properly?',
    answer: 'Your guard should be snug but not uncomfortable or painful. When you bite down there should be light, even contact across your entire bite. While clenching, you should be able to slide your teeth side to side without your teeth locking. If you feel like only one or two teeth are touching when you bite down, or that your teeth are locking, DO NOT CONTINUE wearing your guard and text your Care Team 833.929.2559'
  },
  {
    question: 'My bite feels weird in the morning',
    answer: 'This is normal! Your guard did its job and relaxed your muscles. Your bite will feel normal again soon after waking up.'
  },
  {
    question: 'Why does my dog want to eat my guard?',
    answer: 'Dogs love you and your saliva. Your dog thinks your guard is a custom chew toy - we recommend keeping your guard out of reach of your dog.'
  },
  {
    question: 'Is it okay if my mouth feels dry? What can I do about it?',
    answer: 'While your guard is in, you can sip or rinse your mouth with cool or lukewarm water to hydrate.'
  },
  {
    question: 'Why am I making so much extra saliva?',
    answer: 'Your body just  needs a few days to adjust to wearing your guard. Excess saliva production is normal and will stop after a few days.'
  },
  {
    question: 'How do I get rid of the gunk that\'s building up on my guard?',
    answer: <Markdown>{gunkAnswer}</Markdown>
  }
]

export default faqs
