import { ActionStepper, ActionStepperSize } from '@wallyhealth/web-core'
import WhiteningVideo from '../WhiteningVideo'
import styles from './index.module.css'
import items from './items.json'

const StepsSection = () => (
  <div className={styles.container}>

    <div className={styles.body}>
      <WhiteningVideo/>
      <ActionStepper
        className={styles.steps}
        items={items}
        size={ActionStepperSize.SMALL}
      />
    </div>
  </div>
)

export default StepsSection
