import React, { useState } from 'react'
import { TextHeader, FullPage, Loader } from '@wallyhealth/web-core'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import classes from 'classnames'
import intelService from '../../../services/intelService'
import config from '../../../config'
import ExpressPayOption from './ExpressPayOption'
import styles from './index.module.css'
import CardPayOption from './CardPayOption'
import adsFacade from 'facades/adsFacade'

const JoinPaymentPage = ({ customer, totalCostInCents, planType, prepSubscription }) => {
  const [isStripeInitializing, setIsStripeInitializing] = useState(true)

  const recordPaymentClick = async ({ clickName }) => {
    const { eventId } = await intelService.recordClick({
      clickName,
      viewName: 'JoinPaymentPage',
      identity: customer
    })
    adsFacade.recordPurchaseEvent({ eventId })
  }

  const onStripeInit = () => {
    setIsStripeInitializing(false)
  }

  return (
    <FullPage bodyClassName={styles.container}>

      <TextHeader
        title="One last step, payment."
        subtitle="Once you join your care team will text you how to book your first appointment. And if you change your mind before your first visit, we’ll refund you 100%."
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
      />

      <div className={styles.body}>

        {isStripeInitializing &&
          <Loader
            className={styles.loader}
            label="Loading payment options"
          />
        }

        <div
          className={classes({
            [styles.buttons_loading]: isStripeInitializing,
            [styles.buttons_loaded]: !isStripeInitializing
          })}>
          <CardPayOption
            totalCostInCents={totalCostInCents}
            recordPaymentClick={recordPaymentClick}
            prepSubscription={prepSubscription}
            buttonClassName={styles.card_button}
          />
          <ExpressPayOption
            totalCostInCents={totalCostInCents}
            planType={planType}
            recordPaymentClick={recordPaymentClick}
            prepSubscription={prepSubscription}
            onInit={onStripeInit}
            className={styles.express}
          />
        </div>

      </div>
    </FullPage>
  )
}

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY)

const StripeEnabledJoinPaymentPage = (props) => (
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css2?family=Varta:wght@600&display=swap'
      }],
      mode: 'subscription',
      amount: props.totalCostInCents,
      currency: 'usd',
      setupFutureUsage: 'off_session',
      paymentMethodCreation: 'manual',
      appearance: {
        variables: {
          borderRadius: '30px'
        }
      }
    }}>
    <JoinPaymentPage {...props} />
  </Elements>
)

export default StripeEnabledJoinPaymentPage
