import React, { useEffect } from 'react'
import { FaqSection, BulletsSection } from '@wallyhealth/web-core'
import intelService from '../../services/intelService'
import HeroSection from './HeroSection'
import StepsSection from './StepsSection'
import faqs from './faqs.json'
import safetyItems from './safety.json'
import styles from './index.module.css'

const WhiteningPage = () => {
  useEffect(() => {
    intelService.recordView({ viewName: 'WhiteningPage' })
  }, [])

  return (
    <div className={styles.container}>
      <HeroSection />
      <StepsSection />
      <BulletsSection
        title="Safety"
        items={safetyItems}
      />
      <FaqSection items={faqs} />
    </div>
  )
}

export default WhiteningPage
