import moment from 'moment'
import classes from 'classnames'
import styles from './index.module.css'

const ScheduleTime = ({ time, onSelected, isSelected }) => (
  <div
    className={classes({
      [styles.container]: true,
      [styles.selected]: isSelected
    })}
    onClick={onSelected}>

    <span className={styles.time}>
      {moment(time).format('h:mm')}
    </span>
    <span className={styles.meridiem}>
      {moment(time).format('A')}
    </span>
  </div>
)

export default ScheduleTime
