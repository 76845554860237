import classes from 'classnames'
import styles from './index.module.css'

const PlanSavingsBadge = ({ amount, className }) => (
  <div className={classes(styles.container, className)}>
    <span className={styles.text}>
      SAVE
    </span>
    <span className={styles.amount}>
      ${amount}
    </span>
  </div>
)

export default PlanSavingsBadge
