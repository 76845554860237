import React, { useEffect, useState, useRef, useCallback } from 'react'
import { groupBy, isEmpty, sort, sortBy, toPairs, uniqBy, prop } from 'ramda'
import useDraggableScroll from 'use-draggable-scroll'
import classes from 'classnames'
import ScheduleDay from '../ScheduleDay'
import ScheduleTime from '../ScheduleTime'
import EmptySlots from '../EmptySlots'
import StandbyNotice from '../StandbyNotice'
import { todayPlusDays } from '../../../../utils/timeUtils'
import styles from './index.module.css'

const DAILY_SLOT_SIZE_IN_EM = 4

const groupSlotsByLocalDate = groupBy(slot => slot.localDate)

const resolveUniqueTimesByStartTime = (times) => uniqBy(prop('startDateTimeLocal'))(times)

const compareDates = (slot1, slot2) => {
  return new Date(slot1.localDate).getTime() - new Date(slot2.localDate).getTime()
}
const sortByDate = (slots) => sort(compareDates, slots)

const orderTimesByStartTime = (times) => sortBy(prop('startDateTimeLocal'))(times)

const SlotsWrapper = ({ slots, onSlotSelected, selectedTimeSlot, onTryAgain, className, isFirstLoad }) => {
  const [dailySlotGroups, setDailySlotGroups] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [uniqueTimeSlots, setUniqueTimeSlots] = useState([])
  const [isOnStandby, setIsOnStandby] = useState(false)
  const [canDoStandby, setCanDoStandby] = useState(false)

  const ref = useRef(null)
  const { onMouseDown } = useDraggableScroll(ref)

  const tryMapToDailySlots = () => {
    if (!isEmpty(slots)) {
      mapToDailySlots()
    }
  }
  const mapToDailySlots = useCallback(() => {
    const slotsByDate = toPairs(groupSlotsByLocalDate(slots))
      .map(([localDate, slots]) => ({ localDate, slots }))

    const sortedSlotsByDate = sortByDate(slotsByDate)
    setDailySlotGroups(sortedSlotsByDate)
  }, [slots])

  useEffect(tryMapToDailySlots, [slots, mapToDailySlots])

  const resolveAvailableTimes = useCallback(({ localDate }) => {
    const group = dailySlotGroups.find(g => g.localDate === localDate)
    const uniqueTimes = resolveUniqueTimesByStartTime(group.slots)
    const orderedUniqueTimes = orderTimesByStartTime(uniqueTimes)
    setUniqueTimeSlots(orderedUniqueTimes)
  }, [dailySlotGroups])

  const onDayClicked = useCallback(({ localDate }) => {
    setSelectedDate(localDate)
    resolveAvailableTimes({ localDate })
  }, [resolveAvailableTimes])

  const autoSelectFirstDay = () => {
    if (!isEmpty(dailySlotGroups)) {
      onDayClicked({
        localDate: dailySlotGroups[0].localDate
      })
    }
  }
  useEffect(autoSelectFirstDay, [dailySlotGroups, onDayClicked])

  useEffect(() => {
    const calcCanDoStandby = () => {
      const hasSlots = uniqueTimeSlots.length > 0

      if (hasSlots) {
        const standbyStartDate = todayPlusDays(15)
        const firstAvailableDate = new Date(uniqueTimeSlots[0].localDate)
        setCanDoStandby(firstAvailableDate > standbyStartDate)
      } else {
        setCanDoStandby(false)
      }
    }

    calcCanDoStandby()
  }, [uniqueTimeSlots])

  const onTimeSelected = ({ slot }) => {
    onSlotSelected({ slot, isOnStandby })
  }

  if (isEmpty(dailySlotGroups)) {
    return (
      <EmptySlots
        onTryAgain={onTryAgain}
        isCentered={isFirstLoad}
      />
    )
  }

  return (
    <div className={classes(styles.container, className)}>

      <div
        ref={ref}
        className={styles.days}
        onMouseDown={onMouseDown}
        style={{
          width: `${DAILY_SLOT_SIZE_IN_EM * dailySlotGroups.length}em`
        }}>

        {dailySlotGroups.map(group =>
          <ScheduleDay
            key={`schedule-day-${group.localDate}`}
            dayOfWeek={group.slots[0].dayOfWeek}
            localDate={group.localDate}
            onClick={onDayClicked}
            isSelected={group.localDate === selectedDate}
          />
        )}
      </div>

      {canDoStandby &&
        <StandbyNotice
          isOnStandby={isOnStandby}
          onStandbyFlagChanged={setIsOnStandby}
        />
      }

      {!isEmpty(uniqueTimeSlots) &&
        <div
          className={classes({
            [styles.timeSlots]: true,
            [styles.oneColumn]: uniqueTimeSlots.length === 1,
            [styles.twoColumns]: uniqueTimeSlots.length === 2,
            [styles.threeColumns]: uniqueTimeSlots.length >= 3
          })}>

          {uniqueTimeSlots.map(slot =>
            <ScheduleTime
              key={`schedule-time-${slot.startDateTimeLocal}}`}
              time={slot.startDateTimeLocal}
              onSelected={() => onTimeSelected({ slot })}
              isSelected={slot.startDateTimeLocal === selectedTimeSlot.startDateTimeLocal}
            />
          )}

        </div>
      }
    </div>
  )
}

export default SlotsWrapper
