import React, { useEffect } from 'react'
import { SplitPage, TextHeader, Option } from '@wallyhealth/web-core'
import intelService from '../../../services/intelService'
import studioImgSrc from './studio.jpg'
import styles from './index.module.css'

const PAGE_NAME = 'JoinStudioPage'

const JoinStudioPage = ({ onLocationPicked, onLocationNotPicked, locations }) => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const handleLocationSelect = (location) => {
    intelService.recordClick({
      clickName: 'LocationPick',
      viewName: PAGE_NAME,
      properties: {
        locationId: location.id,
        locationName: location.name
      }
    })

    onLocationPicked(location.id)
  }

  return (
    <SplitPage
      imgSrc={studioImgSrc}
      isLogoVisible={false}
      className={styles.wrapper}
      imageClassName={styles.image}
      bodyClassName={styles.container}>

      <TextHeader
        title="Choose a Wally studio."
        titleClassName={styles.title}
      />

      <div className={styles.locations}>
        {locations.map(location =>
          <Option
            key={`location-${location.id}`}
            title={location.name}
            description={location.address}
            className={styles.option}
            onClick={() => handleLocationSelect(location)}
          />
        )}
      </div>

      <div className={styles.link_wrapper}>
        <span
          className={styles.link}
          onClick={onLocationNotPicked}>
          Not in NYC?
        </span>
      </div>

    </SplitPage>
  )
}

export default JoinStudioPage
