import { Button, ButtonSize } from '@wallyhealth/web-core'
import PlanSavingsBadge from '../PlanSavingsBadge'
import styles from './index.module.css'

const PlanItem = ({ title, description, buttonType, savingsAmount, onSelected }) => (
  <div className={styles.container}>
    <span className={styles.title}>
      {title}
    </span>

    <span className={styles.description}>
      {description}
    </span>

    <Button
      label="Select"
      size={ButtonSize.SMALL}
      type={buttonType}
      className={styles.button}
      onClick={onSelected}
    />

    {savingsAmount &&
      <PlanSavingsBadge
        className={styles.badge}
        amount={savingsAmount}
      />
    }
  </div>
)

export default PlanItem
