import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import { useSearchParams } from 'react-router-dom'
import intelService from '../../services/intelService'
import { centsToDollars } from 'utils/billingUtils'

const WelcomePage = () => {
  const [searchParams] = useSearchParams()

  const amountInCents = searchParams.get('amount') || 0
  const orderId = Date.now()

  const recordView = () => {
    intelService.recordView({ viewName: 'WelcomePage' })
  }

  const ADD_SHOPPERS_SCRIPT_ID = 'AddShoppers'

  const getAddShoppersScript = () => {
    return document.getElementById(ADD_SHOPPERS_SCRIPT_ID)
  }
  const tryInstallAddShoppersScript = () => {
    const isScriptInstalled = !!getAddShoppersScript()
    if (!isScriptInstalled) {
      installAddShoppersScript()
    }
  }
  const installAddShoppersScript = () => {
    window.AddShoppersConversion = {
      order_id: `${orderId}`,
      value: `${centsToDollars(amountInCents)}`
    }
    window.AddShoppersWidgetOptions = {
      loadCss: false,
      pushResponse: false
    }

    const script = document.createElement('script')
    script.id = ADD_SHOPPERS_SCRIPT_ID
    script.src = 'https://shop.pe/widget/widget_async.js#66269c8a29ea3678768f9f0d'
    script.type = 'text/javascript'
    script.async = true
    document.body.appendChild(script)
  }
  const uninstallAddShoppersScript = () => {
    const script = getAddShoppersScript()
    if (script) {
      document.body.removeChild(script)
    }
  }

  const onMount = () => {
    recordView()
    tryInstallAddShoppersScript()
  }
  const onUnmount = () => () => {
    uninstallAddShoppersScript()
  }

  useEffect(() => {
    onMount()
    return onUnmount
  }, [])

  return (
    <ConfirmationPage
      title="Welcome to Wally!"
      subtitle="Your care team will text you soon and help you prepare for your first visit. We look forward to seeing you!"
    />
  )
}

export default WelcomePage
