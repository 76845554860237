import { createClient } from '@wallyhealth/web-core'
import config from 'config'

const client = createClient({
  baseUrl: config.PROVIDER_KAPI_URL,
  timeout: 30000
})

export const fetchStandbySchedulingSlots = ({ linkId }) => {
  return client.get(`/waitlist/availability/${linkId}`)
    .then(response => response.data)
}

export const fetchFulfilledSchedulingSlotIds = ({ linkId }) => {
  return client.get(`/waitlist/fulfilled/${linkId}/ids`)
    .then(response => response.data)
}

export const scheduleStandbyAppointmentSlot = ({ linkId, cohortId, slotId }) => {
  return client.post('/schedule/standby-appointment', { linkId, cohortId, slotId })
    .then(response => response.data)
}
