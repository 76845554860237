import React from 'react'
import ReactDOM from 'react-dom/client'
import { NotificationProvider } from '@wallyhealth/web-core'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { initMonitor } from './services/monitoringService'
import Router from './router'
import './polyfills'
import './index.css'
import '@wallyhealth/web-core/dist/fonts/index.css'

initMonitor()

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
