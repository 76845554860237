import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from '@wallyhealth/web-core'
import { captureError } from '../../services/monitoringService'
import { onboardPatient, prepSubscription, fetchPricing } from './api'

export const usePatient = () => {
  const [isOnboarding, setIsOnboarding] = useState(false)

  const navigate = useNavigate()
  const notifications = useNotifications()

  const tryOnboardPatient = async ({ billing, patient, location }) => {
    try {
      setIsOnboarding(true)
      await onboardPatient({
        billing,
        patient,
        locationId: location.id,
        geoId: location.geoId
      })

      navigate('/welcome')
    } catch (err) {
      setIsOnboarding(false)
      notifications.error(err.message || 'Oops, something went wrong. Please try again.')
    }
  }

  return {
    isOnboarding,
    onboardPatient: tryOnboardPatient
  }
}

export const useBilling = ({ couponCode }) => {
  const notifications = useNotifications()
  const defaultPricing = { plans: [], coupon: {} }

  const [pricing, setPricing] = useState(defaultPricing)
  const [isPricingLoading, setIsPricingLoading] = useState(false)
  const [isPreppingSubscription, setIsPreppingSubscription] = useState(false)
  const [forceReloadEffect, setForceReloadEffect] = useState(0)

  const resolvePricing = async ({ couponCode }) => {
    try {
      setIsPricingLoading(true)
      const pricing = await fetchPricing({ couponCode })
      checkCouponStatus(pricing.coupon)
      setPricing(pricing)
      setIsPricingLoading(false)
    } catch (err) {
      setIsPricingLoading(false)
      setPricing(defaultPricing)
      captureError({ error: err })
    }
  }
  useEffect(() => {
    resolvePricing({ couponCode })
  }, [couponCode, forceReloadEffect])

  const checkCouponStatus = (coupon) => {
    if (coupon.status === 'INVALID') {
      notifications.error('Oops! That coupon is either invalid or expired.')
    }
  }

  const prepSubscription1 = async ({ billing, patient, location }) => {
    setIsPreppingSubscription(true)
    let subscription = {}

    try {
      subscription = await prepSubscription({
        billing,
        patient,
        locationId: location.id,
        geoId: location.geoId
      })
    } catch (err) {
      notifications.error(err.message || 'Oops, something went wrong. Please try again.')
    }

    setIsPreppingSubscription(false)
    return subscription
  }

  return {
    pricing,
    reloadPricing: () => setForceReloadEffect(prev => prev + 1),
    isPricingLoading,
    prepSubscription: prepSubscription1,
    isPreppingSubscription
  }
}
