import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from '@wallyhealth/web-core'
import { setDefaultCard, updateCard } from './api'

export const useBilling = () => {
  const [isUpdating, setIsUpdating] = useState(false)

  const navigate = useNavigate()
  const notifications = useNotifications()

  const tryUpdateCard = async ({ email, encodedPatientId, token }) => {
    try {
      setIsUpdating(true)

      email
        ? await updateCard({ email, token })
        : await setDefaultCard({ encodedPatientId, token })

      navigate('/card/updated')
    } catch (err) {
      setIsUpdating(false)
      notifications.error(err.message || 'Oops, something went wrong. Please try again.')
    }
  }

  return {
    isUpdating,
    updateCard: tryUpdateCard
  }
}
