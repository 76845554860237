import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import intelService from '../../services/intelService'

const CardUpdatedPage = () => {
  useEffect(() => {
    intelService.recordView({ viewName: 'CardUpdatedPage' })
  }, [])

  return (
    <ConfirmationPage
      title="All set!"
      subtitle="Your card has been successfully updated."
    />
  )
}

export default CardUpdatedPage
