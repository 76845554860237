import { createClient } from '@wallyhealth/web-core'
import config from '../../config'

const client = createClient({ baseUrl: config.PROVIDER_KAPI_URL })

export const fetchConfirmation = ({ encodedId }) => {
  return client.get(`/confirmations/${encodedId}`)
    .then(response => response.data)
}

export const confirmAppointment = ({ encodedId }) => {
  return client.post(`/confirmations/${encodedId}/confirm`)
    .then(response => response.data)
}

export const rescheduleAppointment = ({ encodedId }) => {
  return client.post(`/confirmations/${encodedId}/reschedule`)
    .then(response => response.data)
}
