import facebookService from 'services/facebookService'
import tikTokService from 'services/tikTokService'
import postieService from 'services/postieService'

const adsFacade = {

  recordAddToCartEvent: ({ eventId, identity }) => {
    facebookService.recordAddToCartEvent({ eventId })
    tikTokService.recordAddToCartEvent({ eventId })
    postieService.recordAddToCartEvent({ email: identity.email })
  },

  recordPurchaseEvent: ({ eventId }) => {
    facebookService.recordPurchaseEvent({ eventId })
    tikTokService.recordPurchaseEvent({ eventId })
  }
}

export default adsFacade
