import { createClient } from '@wallyhealth/web-core'
import config from '../../config'

const client = createClient({ baseUrl: config.PATIENT_KAPI_URL })

export const setDefaultCard = ({ encodedPatientId, token }) => {
  const body = { encodedPatientId, token }

  return client.post('/billing/cards/default', body)
    .then(response => response.data)
}

export const updateCard = ({ email, token }) => {
  const body = { email, token }

  return client.post('/billing/card', body)
    .then(response => response.data)
}
