import React from 'react'
import { Button } from '@wallyhealth/web-core'
import classes from 'classnames'
import styles from './index.module.css'

const EmptySlots = ({ onTryAgain, isCentered }) => (
  <div className={styles.container}>

    <div
      className={classes({
        [styles.info]: true,
        [styles.centered]: isCentered
      })}>
      <span className={styles.message}>
        We couldn't find any available appointment times. Feel free to text us at
      </span>
      <a
        className={styles.number}
        href="sms:+18339292559">
        (833) 929-2559
      </a>
      <span className={styles.message}>
        .
      </span>
    </div>

    <Button
      label="Try again just in case"
      onClick={onTryAgain}
      className={styles.button}
    />
  </div>
)

export default EmptySlots
