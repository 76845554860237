import React, { useEffect, useState } from 'react'
import { StyledErrorPage, LoadingPage } from '@wallyhealth/web-core'
import { useSearchParams } from 'react-router-dom'
import ConfirmationPage from './ConfirmationPage'
import { useConfirmation } from './hooks'
import { StatusType } from './domain'

const ErrorMessages = {
  [StatusType.RESCHEDULED]: 'This appointment has been rescheduled.',
  [StatusType.CONFIRMED]: 'This appointment has already been confirmed.',
  [StatusType.DISABLED]: 'This appointment cannot be confirmed online.'
}

const GENERIC_ERROR_MESSAGE = 'Ooops, something went wrong.'

const ConfirmationManager = () => {
  const [errorMessage, setErrorMessage] = useState('')

  const [searchParams] = useSearchParams()

  const {
    resolveConfirmation,
    confirmation,
    isLoading,
    shouldRetryLoading,
    confirmAppointment,
    isConfirming,
    rescheduleAppointment,
    isRescheduling
  } = useConfirmation({ encodedId: searchParams.get('id') })

  const tryResolveStatusErrorMessage = () => {
    const message = ErrorMessages[confirmation.statusType]
    message && setErrorMessage(message)
  }
  useEffect(() => {
    if (confirmation) {
      tryResolveStatusErrorMessage()
    }
  }, [confirmation])

  const resolveLoadingErrorMessage = () => {
    shouldRetryLoading && setErrorMessage(GENERIC_ERROR_MESSAGE)
  }
  useEffect(() => {
    resolveLoadingErrorMessage()
  }, [shouldRetryLoading])

  const resolveTryAgainFn = () => {
    return (shouldRetryLoading)
      ? resolveConfirmation
      : null
  }

  const isPerioAppointment = () => {
    return confirmation.appointmentTypes.includes('PERIO_THERAPY')
  }

  const onConfirm = async () => {
    await confirmAppointment()
    window.location.replace('/confirm/success')
  }

  const onReschedule = async () => {
    const rescheduleResult = await rescheduleAppointment()

    if (!rescheduleResult) {
      return null
    } else if (isPerioAppointment()) {
      window.location.replace('/confirm/success?perio=1')
    } else {
      window.location.replace(rescheduleResult.rescheduleLinkUrl)
    }
  }

  if (isLoading) {
    return (
      <LoadingPage
        isLoaderFaded={true}
      />
    )
  }

  if (errorMessage) {
    return (
      <StyledErrorPage
        title="Heads up!"
        description={errorMessage}
        onTryAgain={resolveTryAgainFn()}
      />
    )
  }

  if (!confirmation) {
    return null
  }

  return (
    <ConfirmationPage
      encodedId={searchParams.get('id')}
      confirmation={confirmation}
      onConfirm={onConfirm}
      isConfirming={isConfirming}
      onReschedule={onReschedule}
      isRescheduling={isRescheduling}
    />
  )
}

export default ConfirmationManager
