import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import intelService from '../../../services/intelService'

const PAGE_NAME = 'ScheduledPage'

const ScheduledPage = () => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  return (
    <ConfirmationPage
      title="All set!"
      subtitle="Your appointment has been scheduled."
    />
  )
}

export default ScheduledPage
