import { useMutation } from '@tanstack/react-query'
import { scheduleStandbyAppointmentSlot } from '../api'

export const useScheduleStandbySlot = ({ linkId }) => {
  const scheduleStandbySlotMutation = useMutation({
    mutationFn: ({ cohortId, slotId }) => {
      return scheduleStandbyAppointmentSlot({ linkId, cohortId, slotId })
    }
  })

  return {
    scheduleStandbySlot: scheduleStandbySlotMutation
  }
}
