import React, { useEffect } from 'react'
import { StyledErrorPage } from '@wallyhealth/web-core'
import useQuery from '../../hooks/useQuery'
import { useSlots } from './hooks'
import ScheduleSlotsPage from './ScheduleSlotsPage'

const ScheduleFlow = () => {
  const query = useQuery()
  const linkId = query.get('id')
  const encodedConfirmationId = query.get('ecid')

  const {
    isFetchingSlots,
    isInvalidLinkId,
    fetchSlots,
    slots,
    link,
    location,
    locationId,
    isFirstLoad
  } = useSlots()

  const handleLinkFetch = (props = {}) => {
    if (linkId) {
      fetchSlots({
        linkId,
        locationId: props.locationId
      })
    }
  }
  useEffect(() => {
    handleLinkFetch()
  }, [])

  if (link && link.isUsed) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="Looks like this link has been used already."
      />
    )
  }

  if (link && link.isDisabled) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="Looks like this link has expired."
      />
    )
  }

  if (!linkId || isInvalidLinkId) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="That looks like an invalid link."
      />
    )
  }

  return (
    <ScheduleSlotsPage
      link={link}
      location={location}
      slots={slots}
      onTryAgain={handleLinkFetch}
      selectedLocationId={locationId}
      onLocationSelected={handleLinkFetch}
      isFetchingSlots={isFetchingSlots}
      isFirstLoad={isFirstLoad}
      encodedConfirmationId={encodedConfirmationId}
    />
  )
}

export default ScheduleFlow
