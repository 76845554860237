import React, { useState } from 'react'
import checkSrc from './check.svg'
import couponSrc from './coupon.svg'
import styles from './index.module.css'
import { centsToDollarsTrunc } from 'utils/billingUtils'

const ApplyButton = ({ isDisabled, onClick }) => (
  <input
    disabled={isDisabled}
    className={styles.apply_button}
    type="button"
    value="APPLY"
    onClick={onClick}
  />
)

const CouponIntake = ({ applyCode }) => {
  const [isInEntryMode, setIsInEntryMode] = useState(false)
  const [code, setCode] = useState('')

  const goIntoEntryMode = () => {
    setIsInEntryMode(true)
  }

  return (
    <div className={styles.intake}>
      {!isInEntryMode &&
        <span
          className={styles.coupon_link}
          onClick={goIntoEntryMode}>
          Have a coupon?
        </span>
      }
      {isInEntryMode &&
        <div className={styles.edit}>
          <img
            src={couponSrc}
            className={styles.coupon_icon}
          />
          <input
            className={styles.coupon_input}
            autoFocus={true}
            onChange={({ target }) => setCode(target.value)}
          />
          <ApplyButton
            isDisabled={!code}
            onClick={() => applyCode({ code })}
          />
        </div>
      }
    </div>
  )
}

const CouponConfirmation = ({ discountInCents }) => (
  <div className={styles.container}>
    <img
      className={styles.check}
      src={checkSrc}
      alt="checkmark"
    />
    <div className={styles.text}>
      <span className={styles.discount}>
        ${centsToDollarsTrunc(discountInCents)} off
      </span>
      <span className={styles.trailing}>
        applied
      </span>
    </div>
  </div>
)

const Coupon = ({ discountInCents, applyCode }) => {
  return discountInCents
    ? <CouponConfirmation discountInCents={discountInCents} />
    : <CouponIntake applyCode={applyCode} />
}

export default Coupon
