import React, { useState, useEffect } from 'react'
import { TextHeader, FullPage, Button, TextInput, useNotifications } from '@wallyhealth/web-core'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useSearchParams } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import config from '../../config'
import intelService from '../../services/intelService'
import styles from './index.module.css'
import { useBilling } from './hooks'

const ELEMENTS_OPTIONS = {
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css2?family=Varta:wght@600&display=swap'
  }]
}

const resolveStripeFontSize = () => {
  return (window.innerWidth > 992) ? 20 : 16
}

const createStripeOptions = () => {
  const fontSize = resolveStripeFontSize()

  return {
    hideIcon: true,
    style: {
      base: {
        fontSize: `${fontSize}px`,
        fontFamily: 'Varta',
        color: '#106A5C',
        '::placeholder': {
          color: '#106A5C'
        },
        fontWeight: '600'
      },
      invalid: {
        color: '#C23D4B'
      }
    }
  }
}

const CardUpdatePage = () => {
  const [search] = useSearchParams()
  const notifications = useNotifications()
  const stripe = useStripe()
  const elements = useElements()
  const { isUpdating, updateCard } = useBilling()

  const [email, setEmail] = useState('')
  const [zip, setZip] = useState('')

  const [isCardComplete, setIsCardComplete] = useState(false)
  const [isGeneratingToken, setIsGeneratingToken] = useState(false)

  const encodedPatientId = search.get('epid')

  const canSubmit = () => {
    return (isEmail(email) || encodedPatientId) && isCardComplete
  }

  useEffect(() => {
    intelService.recordView({ viewName: 'CardUpdatePage' })
  }, [])

  const onCardChange = ({ complete, value }) => {
    setIsCardComplete(complete)
    setZip(value.postalCode)
  }

  const onTokenGenerated = async (token) => {
    await updateCard({
      encodedPatientId,
      email,
      token
    })
  }

  const onSubmit = () => {
    if (!isGeneratingToken) {
      generateToken()
        .then(onTokenGenerated)
        .catch(onTokenError)
    }
  }

  const promisifyResult = (result) => {
    setIsGeneratingToken(false)

    return (result.error)
      ? Promise.reject(result.error)
      : Promise.resolve(result.token.id)
  }

  const generateToken = () => {
    setIsGeneratingToken(true)

    return stripe.createToken(
      elements.getElement(CardElement), {
        address_zip: zip,
        address_country: 'US',
        currency: 'usd'
      }
    ).then(promisifyResult)
  }

  const onTokenError = (error) => {
    notifications.error(error.message)
  }

  return (
    <FullPage bodyClassName={styles.container}>

      <TextHeader
        title="Update your card."
        subtitle="You can use any major credit card. Your FSA/HSA card works too."
        className={styles.header}
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
      />

      <div className={styles.form}>

        {!encodedPatientId &&
          <TextInput
            label="Email"
            className={styles.email}
            onValueChange={setEmail}
            value={email}
          />
        }

        <div className={styles.card}>
          <CardElement
            onChange={onCardChange}
            options={createStripeOptions()}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            className={styles.submit}
            label="Submit"
            disabled={!canSubmit()}
            onClick={onSubmit}
            isLoading={isGeneratingToken || isUpdating}
          />
        </div>

      </div>
    </FullPage>
  )
}

const StripeEnabledCardUpdatePage = (props) => (
  <Elements
    stripe={loadStripe(config.STRIPE_PUBLISHABLE_KEY)}
    options={ELEMENTS_OPTIONS}>
    <CardUpdatePage {...props} />
  </Elements>
)

export default StripeEnabledCardUpdatePage
