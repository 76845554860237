import { useState } from 'react'
import { Toggle, Accordion } from '@wallyhealth/web-core'
import styles from './index.module.css'

const StandbyNotice = ({ isOnStandby, onStandbyFlagChanged }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Accordion
      id="standby-accordion"
      className={styles.container}
      title=" Looking for an earlier date?"
      titleClassName={styles.title}
      iconClassName={styles.icon}
      isExpanded={isExpanded}
      onToggle={(({ isExpanded }) => setIsExpanded(isExpanded))}
     >
        <div className={styles.body}>

          <span className={styles.description}>
            Join our standby list when you book to hear about openings. Most members get a standby appointment within 2 weeks.
          </span>

          <Toggle
            className={styles.toggle}
            isToggledOn={isOnStandby}
            onToggleChanged={onStandbyFlagChanged}
            label="Join standby list"
          />

        </div>
    </Accordion>
  )
}

export default StandbyNotice
