import { createClient } from '@wallyhealth/web-core'
import config from '../../config'

const client = createClient({
  baseUrl: config.PROVIDER_KAPI_URL,
  timeout: 30000
})

export const fetchOpenSlots = ({ linkId, locationId }) => {
  const params = {
    linkId,
    locationId
  }

  return client.get('/schedule/slots', { params })
    .then(response => response.data)
}

export const bookAppointment = ({
  linkId,
  startTimeUtc,
  startTimeLocal,
  durationInMinutes,
  appointmentType,
  providerId,
  podId,
  locationId,
  isOnStandby,
  encodedConfirmationId
}) => {
  const body = {
    linkId,
    appointment: {
      startTimeUtc,
      startTimeLocal,
      durationInMinutes,
      appointmentType,
      providerId,
      podId
    },
    locationId,
    isOnStandby,
    encodedConfirmationId
  }

  return client.post('/schedule/appointments', body)
    .then(response => response.data)
}
