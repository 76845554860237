import { createClient } from '@wallyhealth/web-core'
import config from '../../config'

const client = createClient({ baseUrl: config.PATIENT_KAPI_URL })

export const onboardPatient = ({ billing, patient, addresses = {}, locationId, geoId }) => {
  const body = { billing, patient, addresses, locationId, geoId }

  return client.post('/patients/paying', body)
    .then(response => response.data)
}

export const prepSubscription = ({ billing, patient, addresses = {}, locationId, geoId }) => {
  const body = { billing, patient, addresses, locationId, geoId }

  return client.post('/billing/subscriptions/prepped', body)
    .then(response => response.data)
}

export const fetchPricing = ({ couponCode }) => {
  const params = couponCode
    ? { coupon: couponCode }
    : {}

  return client.get('/billing/pricing', { params })
    .then(response => response.data)
}
