import { both, is, all } from 'ramda'

const isArrayofStrings = both(
  is(Array),
  all(is(String))
)

export const createEnum = (values) => {
  if (!isArrayofStrings(values)) {
    throw new Error('arg is not array of strings')
  }

  const enumObject = {}

  for (const val of values) {
    enumObject[val] = val
  }

  return Object.freeze(enumObject)
}
