const postieService = {

  recordEvent: ({ eventName, eventDetails }) => {
    if (window.letterpress) {
      window.letterpress(eventName, eventDetails)
    }
  },

  recordAddToCartEvent: ({ email }) => {
    postieService.recordEvent({
      eventName: 'trackUnstructEvent',
      eventDetails: {
        schema: 'iglu:com.getletterpress/email/jsonschema/1-0-0',
        data: {
          email
        }
      }
    })
  }
}

export default postieService
