import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import styles from './index.module.css'

const ScheduleDay = ({ localDate, onClick, isSelected }) => (

  <div
    className={styles.container}
    onClick={() => onClick({ localDate })}>

    <div className={styles.header}>
      <span className={classNames({
        [styles.dayOfWeek]: true,
        [styles.dayOfWeekSelected]: isSelected
      })}>
        {moment(localDate).format('ddd')}
      </span>
    </div>

    <div className={classNames({
      [styles.body]: true,
      [styles.bodySelected]: isSelected
    })}>
      <span className={styles.day}>
        {moment(localDate).format('D')}
      </span>
      <span className={styles.month}>
        {moment(localDate).format('MMM')}
      </span>

    </div>
  </div>
)

export default ScheduleDay
