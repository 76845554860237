import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchStandbySchedulingSlots } from './api'

export const useStandbyScheduleSlots = ({ linkId }) => {
  const { data: standbyScheduleData = { slots: {} }, isLoading, isError } = useQuery({
    queryKey: ['fetchStandbySchedulingSlots', linkId],
    queryFn: () => fetchStandbySchedulingSlots({ linkId }),
    retry: false,
    throwOnError: false
  })

  const isSchedulingLinkExpired = useMemo(() => standbyScheduleData.linkStatus === 'EXPIRED', [standbyScheduleData])

  const isSchedulingLinkInvalid = useMemo(() => isError || standbyScheduleData.linkStatus === 'INVALID', [isError, standbyScheduleData])

  const isSchedulingLinkUsed = useMemo(() => standbyScheduleData.linkStatus === 'USED', [standbyScheduleData])

  const isSchedulingLinkDisabled = useMemo(() => standbyScheduleData.linkStatus === 'DISABLED', [standbyScheduleData])

  return {
    schedulingData: standbyScheduleData ?? {},
    isSchedulingLinkInvalid,
    isSchedulingLinkUsed,
    isSchedulingLinkDisabled,
    isSchedulingLinkExpired,
    isLoadingSlots: isLoading
  }
}
