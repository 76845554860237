import React, { useEffect, useState } from 'react'
import { FullPage, TextHeader, TextInput, Button, useNotifications } from '@wallyhealth/web-core'
import trim from 'validator/lib/trim'
import { isValidPhoneNumber } from '../../../validators/phoneValidator'
import { isValidEmail } from '../../../validators/emailValidator'
import intelService from '../../../services/intelService'
import styles from './index.module.css'
import adsFacade from 'facades/adsFacade'

const PAGE_NAME = 'JoinInfoPage'

const JoinStartPageBody = ({ onSubmit }) => {
  const notifications = useNotifications()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const sanitize = (value) => {
    return trim(value)
  }

  const isButtonEnabled = () => {
    return sanitize(firstName) && sanitize(lastName) && sanitize(email) && sanitize(phone)
  }

  const handleSubmit = () => {
    const sanitizedInputs = {
      firstName: sanitize(firstName),
      lastName: sanitize(lastName),
      email: sanitize(email),
      phone: sanitize(phone)
    }

    if (!isValidEmail(sanitizedInputs.email)) {
      notifications.error('Please enter a valid email.')
      return
    }

    if (!isValidPhoneNumber(sanitizedInputs.phone)) {
      notifications.error('Please enter a valid US phone number.')
      return
    }

    recordSubmitClick(sanitizedInputs)

    onSubmit(sanitizedInputs)
  }

  const recordSubmitClick = async (sanitizedInputs) => {
    const { eventId } = await intelService.recordClick({
      clickName: 'GetStarted',
      viewName: PAGE_NAME,
      identity: sanitizedInputs
    })
    adsFacade.recordAddToCartEvent({
      eventId,
      identity: sanitizedInputs
    })
  }

  return (
    <div className={styles.wrapper}>
      <TextHeader
        className={styles.header}
        title="Tell us about yourself."
      />

      <div className={styles.form}>
        <div className={styles.names}>
          <TextInput
            className={styles.name}
            label="First Name"
            value={firstName}
            onValueChange={setFirstName}
          />

          <TextInput
            className={styles.name}
            label="Last Name"
            value={lastName}
            onValueChange={setLastName}
          />
        </div>

        <TextInput
          className={styles.email}
          label="Email"
          value={email}
          onValueChange={setEmail}
        />

        <TextInput
          label="Phone"
          className={styles.phone}
          onValueChange={setPhone}
          value={phone}
        />

         <Button
          className={styles.button}
          onClick={handleSubmit}
          disabled={!isButtonEnabled()}
          label="Next"
        />
      </div>

    </div>
  )
}

const JoinStartPage = ({ onSubmit, couponCode }) => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  return (
    <FullPage
      bodyClassName={styles.body}>
      <JoinStartPageBody onSubmit={onSubmit} />
    </FullPage>
  )
}

export default JoinStartPage
