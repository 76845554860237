import React from 'react'
import { TextHeader, BackButton, Button, Modal, ButtonType, ButtonSize } from '@wallyhealth/web-core'
import styles from './index.module.css'

const resolveSubtitle = (appointmentTypes) => {
  return appointmentTypes.includes('PERIO_THERAPY')
    ? 'By continuing, your periodontal therapy plan will be canceled, and your Care Team will text you and help schedule new times.'
    : 'By continuing, your current appointment will be canceled, but you can book a new one right away.'
}

const CancellationModal = ({ isOpen, onClosed, onContinue, appointmentTypes }) => (
  <Modal
    containerClassName={styles.container}
    bodyClassName={styles.body}
    isOpen={isOpen}
    onClosed={onClosed}>

    <TextHeader
      title="Just a heads up"
      titleClassName={styles.title}
      subtitle={resolveSubtitle(appointmentTypes)}
      subtitleClassName={styles.subtitle}
    />

    <div className={styles.buttons}>
      <Button
        className={styles.button}
        type={ButtonType.DEFAULT}
        size={ButtonSize.SMALL}
        label="Continue"
        onClick={onContinue}
      />
      <BackButton
        onClick={onClosed}
        className={styles.back}
      />
    </div>
  </Modal>
)

export default CancellationModal
