import { TextHeader, Logo } from '@wallyhealth/web-core'
import styles from './index.module.css'

const HeroSection = () => (
  <div className={styles.container}>
    <div className={styles.body}>

      <Logo
        className={styles.logo}
        color="#106A5C"
      />

      <TextHeader
        title={
          <h1 className={styles.title}>
            Here's to pearlier pearly whites!
          </h1>
        }
        subtitle={
          <span className={styles.subtitle}>
            Open your whitening kit and let's get started!
          </span>
        }
        className={styles.header}
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
      />
    </div>
  </div>
)

export default HeroSection
