import { useState } from 'react'
import config from '../../config'
import { fetchOpenSlots, bookAppointment } from './api'

const redirectToForm = ({ path, token }) => {
  window.location.replace(`${config.INFO_WEB_URL}/${path}?t=${token}`)
}

export const useSlots = () => {
  const [state, setState] = useState({
    link: null,
    location: null,
    locationId: null,
    slots: [],
    isInvalidLinkId: false,
    isFetchingSlots: false,
    hasSlotsFetchErrored: false,
    isFirstLoad: true,
    form: null
  })

  const tryFetchSlots = async ({ linkId, locationId = null }) => {
    setState(prevState => ({
      ...prevState,
      isFetchingSlots: true
    }))

    try {
      const newState = await fetchSlots({ linkId, locationId })

      if (newState.form && !newState.form.isCompleted) {
        redirectToForm(newState.form)
        return
      }

      setState(prevState => ({
        ...prevState,
        ...newState,
        isFetchingSlots: false,
        hasSlotsFetchErrored: false,
        isInvalidLinkId: false
      }))
    } catch (err) {
      setState(prevState => ({
        ...prevState,
        isFetchingSlots: false,
        hasSlotsFetchErrored: true,
        isInvalidLinkId: err.status === 400
      }))
    }
  }

  const fetchSlots = async ({ linkId, locationId }) => {
    if (!state.isFetchingSlots) {
      const result = await fetchOpenSlots({ linkId, locationId })

      return {
        link: result.link,
        slots: result.slots || [],
        location: result.location,
        locationId: locationId || result.location.id,
        isFirstLoad: false,
        form: result.form
      }
    }
  }

  return {
    isFetchingSlots: state.isFetchingSlots,
    isInvalidLinkId: state.isInvalidLinkId,
    hasSlotsFetchErrored: state.hasSlotsFetchErrored,
    fetchSlots: tryFetchSlots,
    slots: state.slots,
    link: state.link,
    location: state.location,
    locationId: state.locationId,
    isFirstLoad: state.isFirstLoad
  }
}

export const useAppointments = () => {
  const [state, setState] = useState({
    isBooking: false,
    hasBookingFailed: false
  })

  const tryBookAppointment = async (data) => {
    setState(prevState => ({
      ...prevState,
      isBooking: true,
      hasBookingFailed: false
    }))

    try {
      await doBookAppointment(data)

      setState(prevState => ({
        ...prevState,
        isBooking: false,
        hasBookingFailed: false
      }))
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        isBooking: false,
        hasBookingFailed: true
      }))
      throw error
    }
  }

  const doBookAppointment = async (data) => {
    if (!state.isBooking) {
      await bookAppointment(data)
    }
  }

  return {
    hasBookingFailed: state.hasBookingFailed,
    bookAppointment: tryBookAppointment
  }
}
