import React, { useEffect, useState } from 'react'
import classes from 'classnames'
import styles from './index.module.css'
import cancelIconSrc from './cancel.svg'
import loaderIconSrc from './loader.svg'

const INTERVAL_IN_MS = 1000

const BookingActionType = {
  PREPARING: 'PREPARING',
  BOOKING: 'BOOKING'
}

const ActionTypeIcon = {
  [BookingActionType.PREPARING]: cancelIconSrc,
  [BookingActionType.BOOKING]: loaderIconSrc
}

const BookingAction = ({ onCanceled, onTriggered }) => {
  const [secondsLeft, setSecondsLeft] = useState(5)
  const [label, setLabel] = useState('')
  const [actionType, setActionType] = useState(BookingActionType.PREPARING)

  const calculateSecondsLeft = () => {
    setSecondsLeft(secondsLeft => --secondsLeft)
  }

  const onBookingTriggered = () => {
    setActionType(BookingActionType.BOOKING)
    onTriggered()
  }

  const setUpCountdownInterval = () => {
    const interval = setInterval(calculateSecondsLeft, INTERVAL_IN_MS)

    if (secondsLeft === 0) {
      clearInterval(interval)
      onBookingTriggered()
    }
    return () => clearInterval(interval)
  }
  useEffect(setUpCountdownInterval, [secondsLeft])

  const onButtonClick = () => {
    if (actionType === BookingActionType.PREPARING) {
      onCanceled()
    }
  }

  const resolveLabel = () => {
    const nextLabel = (secondsLeft > 0)
      ? `Booking in ${secondsLeft}...`
      : 'Booking...'

    setLabel(nextLabel)
  }
  useEffect(resolveLabel, [secondsLeft])

  return (
    <div className={styles.container}>

      <div className={styles.text}>
        {label}
      </div>
      <div
        className={styles.action}
        onClick={onButtonClick}>

        <img
          className={classes({
            [styles.icon]: true,
            [styles.rotation]: actionType === BookingActionType.BOOKING
          })}
          src={ActionTypeIcon[actionType]}
          alt=""
        />
      </div>

    </div>
  )
}

export default BookingAction
