import React, { useEffect } from 'react'
import { SplitPage, TextHeader, Button, BackButton } from '@wallyhealth/web-core'
import intelService from '../../../services/intelService'
import coverImgSrc from './studio.jpg'
import styles from './index.module.css'

const redirectToHome = () => {
  window.location.href = 'https://www.carebywally.com'
}

const JoinWaitlistPage = ({ goBack }) => {
  useEffect(() => {
    intelService.recordView({ viewName: 'JoinWaitlistPage' })
  }, [])

  return (
    <SplitPage
      imgSrc={coverImgSrc}
      isLogoVisible={false}
      bodyClassName={styles.body}
      className={styles.container}
      imageClassName={styles.cover}>

      {!!goBack &&
        <BackButton
          className={styles.back}
          onClick={goBack}
        />
      }

      <TextHeader
        title="We're hitting pause on new signups."
        subtitle="To keep up with the high demand and maintain a stellar experience for all our members, we're temporarily pausing new sign-ups. Hop onto the waitlist and we'll ping you as soon as your spot opens up!"
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
      />

      <div className={styles.buttons}>
        <Button
          label="Join the waitlist"
          className={styles.button}
          onClick={redirectToHome}
        />
      </div>

    </SplitPage>
  )
}

export default JoinWaitlistPage
