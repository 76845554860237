import React, { useState, useEffect } from 'react'
import { TextHeader, FullPage, Button, useNotifications } from '@wallyhealth/web-core'
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { centsToDollarsTrunc } from '../../../utils/billingUtils'
import config from '../../../config'
import intelService from '../../../services/intelService'
import styles from './index.module.css'
import adsFacade from 'facades/adsFacade'

const PAGE_NAME = 'JoinPaymentPage'

const ELEMENTS_OPTIONS = {
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css2?family=Varta:wght@600&display=swap'
  }]
}

const resolveStripeFontSize = () => {
  return (window.innerWidth > 768) ? 20 : 16
}

const createStripeOptions = (options = {}) => {
  const fontSize = resolveStripeFontSize()

  return {
    placeholder: options.placeholder,
    hideIcon: true,
    style: {
      base: {
        fontSize: `${fontSize}px`,
        fontFamily: 'Varta',
        color: '#106A5C',
        '::placeholder': {
          color: '#106A5C'
        },
        fontWeight: '600'
      },
      invalid: {
        color: '#C23D4B'
      }
    }
  }
}

const JoinPaymentPage = ({ isProcessing, customer, onTokenGenerated, totalCostInCents }) => {
  const notifications = useNotifications()
  const stripe = useStripe()
  const elements = useElements()

  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false)
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false)
  const [isCardCvcComplete, setIsCardCvcComplete] = useState(false)
  const [isGeneratingToken, setIsGeneratingToken] = useState(false)

  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const areInputsValid = () => {
    return isCardNumberComplete && isCardExpiryComplete && isCardCvcComplete
  }

  const handleToken = (token) => {
    onTokenGenerated({ token })
  }

  const tryGenerateToken = () => {
    recordPaymentClick()

    if (!isGeneratingToken) {
      generateToken()
        .then(handleToken)
        .catch(onTokenError)
    }
  }

  const recordPaymentClick = async () => {
    const { eventId } = await intelService.recordClick({
      clickName: 'PayClick',
      viewName: PAGE_NAME,
      identity: customer
    })
    adsFacade.recordPurchaseEvent({ eventId })
  }

  const promisifyResult = (result) => {
    setIsGeneratingToken(false)

    return (result.error)
      ? Promise.reject(result.error)
      : Promise.resolve(result.token.id)
  }

  const generateToken = () => {
    setIsGeneratingToken(true)

    return stripe.createToken(
      elements.getElement(CardNumberElement)
    ).then(promisifyResult)
  }

  const onTokenError = (error) => {
    notifications.error(error.message)
  }

  const onCardNumberChanged = ({ complete }) => {
    setIsCardNumberComplete(complete)
  }

  const onCardExpiryChanged = ({ complete }) => {
    setIsCardExpiryComplete(complete)
  }

  const onCardCvcChanged = ({ complete }) => {
    setIsCardCvcComplete(complete)
  }

  return (
    <FullPage bodyClassName={styles.container}>

      <TextHeader
        title="One last step, payment."
        subtitle="Once you join your care team will text you how to book your first appointment. And if you change your mind before your first visit, we’ll refund you 100%."
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
      />

      <div className={styles.form}>

        <div className={styles.card}>
          <div className={styles.card_element}>
            <CardNumberElement
              onChange={onCardNumberChanged}
              options={createStripeOptions({ placeholder: 'Card number' })}
            />
          </div>

          <div className={styles.card_columns}>
            <div className={styles.card_expiry}>
              <CardExpiryElement
                options={createStripeOptions()}
                onChange={onCardExpiryChanged}
              />
            </div>

            <div className={styles.card_cvc}>
              <CardCvcElement
                options={createStripeOptions()}
                onChange={onCardCvcChanged}
              />
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button
            className={styles.submit}
            label={`Pay $${centsToDollarsTrunc(totalCostInCents)} today`}
            disabled={!areInputsValid()}
            onClick={tryGenerateToken}
            isLoading={isGeneratingToken || isProcessing}
          />
        </div>

      </div>
    </FullPage>
  )
}

const StripeEnabledJoinPaymentPage = (props) => (
  <Elements
    stripe={loadStripe(config.STRIPE_PUBLISHABLE_KEY)}
    options={ELEMENTS_OPTIONS}>
    <JoinPaymentPage {...props} />
  </Elements>
)

export default StripeEnabledJoinPaymentPage
