import { useState, useEffect } from 'react'
import { useNotifications } from '@wallyhealth/web-core'
import { fetchConfirmation, confirmAppointment, rescheduleAppointment } from './api'
import { StatusType } from './domain'
import config from 'config'

export const useConfirmation = ({ encodedId }) => {
  const notifications = useNotifications()

  const [isLoading, setIsLoading] = useState(false)
  const [confirmation, setConfirmation] = useState()
  const [shouldRetryLoading, setShouldRetryLoading] = useState(false)

  const tryHandleConfirmation = ({ statusType, rescheduleLinkUrl, ...other }) => {
    if (statusType === StatusType.RESCHEDULE_PENDING) {
      window.location.replace(rescheduleLinkUrl)
    }
  }

  const tryHandleInvalidId = ({ status }) => {
    if (status === 400) {
      window.location.replace(config.HOMEPAGE_URL)
    }
  }

  const tryHandleMissingId = () => {
    if (!encodedId) {
      window.location.replace(config.HOMEPAGE_URL)
    }
  }

  const handleServerError = ({ status }) => {
    setShouldRetryLoading(true)
  }

  const resolveConfirmation1 = async () => {
    try {
      tryHandleMissingId()
      setIsLoading(true)
      setShouldRetryLoading(false)
      await resolveConfirmation2()
    } catch (err) {
      tryHandleInvalidId(err)
      handleServerError(err)
    } finally {
      setIsLoading(false)
    }
  }
  const resolveConfirmation2 = async () => {
    const result = await fetchConfirmation({ encodedId })
    tryHandleConfirmation(result)
    setConfirmation(result)
  }
  useEffect(() => {
    resolveConfirmation1()
  }, [encodedId])

  const [isConfirming, setIsConfirming] = useState(false)

  const confirmAppointment1 = async () => {
    if (isConfirming) return

    try {
      setIsConfirming(true)
      await confirmAppointment({ encodedId })
    } catch (err) {
      notifications.error('Could not confirm the appointment. Please try again.')
      throw err
    } finally {
      setIsConfirming(false)
    }
  }

  const [isRescheduling, setIsRescheduling] = useState(false)

  const rescheduleAppointment1 = async () => {
    if (isRescheduling) return

    try {
      setIsRescheduling(true)
      return await rescheduleAppointment({ encodedId })
    } catch (err) {
      notifications.error('Could not reschedule the appointment. Please try again.')
      throw err
    } finally {
      setIsRescheduling(false)
    }
  }

  return {
    isLoading,
    shouldRetryLoading,
    confirmation,
    resolveConfirmation: resolveConfirmation1,
    confirmAppointment: confirmAppointment1,
    isConfirming,
    rescheduleAppointment: rescheduleAppointment1,
    isRescheduling
  }
}
