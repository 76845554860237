import stageConfig from './stage.json'
import prodConfig from './prod.json'

const CONFIG = {
  STAGE: stageConfig,
  PROD: prodConfig
}

export const getEnvType = () => {
  return (window.__ENV === 'PROD') ? 'PROD' : 'STAGE'
}

export default CONFIG[getEnvType()]
