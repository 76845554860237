/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { useNavigate } from 'react-router-dom'
import { FullPage, TextHeader, Loader } from '@wallyhealth/web-core'
import moment from 'moment'
import { useStandbyScheduleSlots } from '../useStandbyScheduleSlots.hook'
import { useFetchFulfilledSlots } from './useFetchFulfilledSlots.hook'
import { useScheduleStandbySlot } from './useScheduleStandbySlot.hook'
import styles from './index.module.css'
import intelService from 'services/intelService'
import BookingAction from 'flows/schedule/ScheduleSlotsPage/BookingAction'

const BOOKING_ERROR_MAP = {
  standby_hold_taken: 'The time you attempted to book was taken by another patient, please pick another time',
  standby_outreach_expired: 'Sorry, all spots have been snagged'
}

const generateGoogleMapsLink = (address) => `https://maps.google.com?q=${address.replace(' ', '+')}+New+York`

const StandbySlotLocation = ({ locationName, slots, unavailableSlotIds = [], addressMap }) => {
  const trackMapLinkClicked = (locationName) => {
    intelService.recordClick({
      clickName: 'standby-location-map-link',
      viewName: window.location.href,
      properties: {
        locationName
      }
    })
  }

  const locationAddress = addressMap[locationName]

  if (!slots.length) {
    return null
  }

  return (
      <div className={styles.standbySlotLocationContainer}>
          <div className={styles.standbyLocationTextContainer}>
              <div className={styles.locationTextHeader}>
                <h3 className={styles.standbyLocationHeader}>{locationName}</h3>
                <a className={styles.standbyLocationMapLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => trackMapLinkClicked(locationName)}
                  href={generateGoogleMapsLink(locationAddress)}>{locationAddress}
                </a>
              </div>
              <div role='separator' className={styles.locationDivider}/>
          </div>
          { !!slots.length &&
              <div className={styles.standbySlotButtonContainer}>
                  { slots.map(slot => (
                      <ToggleGroup.Item key={slot.id} className={styles.standbySlotButton} value={slot.id} disabled={unavailableSlotIds.includes(slot.id)}>
                          {moment(slot.startTimeLocal).format('h:mm A')}
                          { unavailableSlotIds.includes(slot.id) ? <span>(taken)</span> : null}
                      </ToggleGroup.Item>
                  ))}
              </div>
          }
      </div>
  )
}

const StandbyPage = ({ linkId }) => {
  const navigate = useNavigate()
  const { fulfilledSlotIds } = useFetchFulfilledSlots({ linkId })

  const [slotValue, setSlotValue] = useState(null)
  const [isBooking, setIsBooking] = useState(false)
  const [isOutreachExpired, setIsOutreachExpired] = useState(false)
  const [errorText, setErrorText] = useState()

  const { schedulingData, isLoadingSlots } = useStandbyScheduleSlots({ linkId })
  const { slots: locationSlotMap, cohortId, addressMap } = schedulingData ?? {}

  const { scheduleStandbySlot } = useScheduleStandbySlot({ linkId })

  useEffect(() => {
    intelService.recordView({
      viewName: 'StandbyPage',
      properties: {
        linkId,
        cohortId
      }
    })
  }, [])

  const onBookingConfirmed = () => {
    scheduleStandbySlot.mutate({ slotId: slotValue, cohortId }, {
      onSuccess: () => {
        intelService.recordEvent({
          eventName: 'booked-standby-appointment',
          properties: {
            linkId,
            cohortId,
            slotValue
          }
        })
        navigate('/standby-schedule/confirmed')
      },
      onError: (error) => {
        intelService.recordEvent({
          eventName: 'failed-booking-standby-appointment',
          properties: {
            linkId,
            cohortId,
            slotValue,
            errorMessage: error.message
          }
        })

        if (error.message === 'standby_outreach_expired') {
          setIsOutreachExpired(true)
        }

        setErrorText(BOOKING_ERROR_MAP[error.message] ?? 'Sorry, we encountered a problem booking your appointment, please try again')
        setIsBooking(false)
      }
    })
  }

  const onBookingCancelled = () => {
    intelService.recordEvent({
      eventName: 'cancelled-standby-appointment-choice',
      properties: {
        slotValue,
        linkId,
        cohortId
      }
    })

    setIsBooking(false)
    setSlotValue(null)
  }

  if (isLoadingSlots) {
    return (
      <FullPage bodyClassName={styles.standbyRootLoading}>
        <Loader/>
      </FullPage>
    )
  }

  return (
    <FullPage bodyClassName={styles.standbyRoot}>
        <div>
          <TextHeader
              className={styles.standbyHeaderContainer}
              titleClassName={styles.standbyHeader}
              title={'Snag a time that works for you tomorrow.'}
              subtitle={"If your preferred location isn't shown, it's because there are no available times."}
              subtitleClassName={styles.standbySubheader}/>
          { !!errorText && <p className={styles.errorText}>{errorText}</p> }
        </div>
        { !isOutreachExpired &&
            <ToggleGroup.Root value={slotValue} onValueChange={(value) => {
              setSlotValue(value)
              setIsBooking(!!value)
            }} className={styles.standbySlotContainer} type="single" aria-label="available standby times">
                {
                    Object.keys(locationSlotMap).map(location => (
                        <StandbySlotLocation key={location} addressMap={addressMap} locationName={location} slots={locationSlotMap[location]} unavailableSlotIds={fulfilledSlotIds}/>
                    ))
                }
            </ToggleGroup.Root>
        }
        {isBooking &&
            <div className={styles.standbyActionContainer}>
                <BookingAction onCanceled={onBookingCancelled} onTriggered={() => onBookingConfirmed()}/>
            </div>
        }
    </FullPage>
  )
}

export default StandbyPage
