import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import intelService from '../../../services/intelService'

const PAGE_NAME = 'StandbyScheduledPage'

const StandbyScheduledPage = () => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  return (
    <ConfirmationPage
      title="Awesome, it's yours!"
      subtitle="Your original appointment is cancelled and moved to tomorrow"
    />
  )
}

export default StandbyScheduledPage
