import React, { useEffect } from 'react'
import { Header, BulletsSection, BulletsColorScheme, FaqSection, SplitSection } from '@wallyhealth/web-core'
import intelService from '../../services/intelService'
import StepsSection from './StepsSection'
import faqs from './faqs.js'
import careItems from './careItems.json'
import styles from './index.module.css'
import heroImgSrc from './hero.jpg'

const NightGuardPage = () => {
  useEffect(() => {
    intelService.recordView({ viewName: 'NightGuardPage' })
  }, [])

  return (
    <div className={styles.container}>
      <Header />
      <SplitSection
        title="Your Guide to Night Guard."
        description="Follow the steps below to set up and maintain your guard."
        imageSrc={heroImgSrc}
      />
      <StepsSection />
      <BulletsSection
        title="Wear & Care"
        items={careItems}
        isNumbered={true}
        isDense={false}
        colorScheme={BulletsColorScheme.WHITE}
      />
      <FaqSection
        items={faqs}
      />
    </div>
  )
}

export default NightGuardPage
