import React, { useEffect } from 'react'
import { ButtonType, FullPage, TextHeader } from '@wallyhealth/web-core'
import { PlanType } from '../shared'
import { centsToDollarsTrunc } from '../../../utils/billingUtils'
import intelService from '../../../services/intelService'
import PlanItem from './PlanItem'
import Coupon from './Coupon'
import ErrorTreatment from './ErrorTreatment'
import styles from './index.module.css'

const PAGE_NAME = 'JoinPlanPage'

const JoinPlanPage = ({ onPlanSelected, pricing, reloadPricing, isPricingLoading, applyCouponCode }) => {
  const annualPlan = pricing.plans.find(plan => plan.planType === PlanType.ANNUAL)
  const monthlyPlan = pricing.plans.find(plan => plan.planType === PlanType.MONTHLY)

  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const handlePlanSelect = ({ planType, totalCostInCents }) => {
    intelService.recordClick({
      clickName: 'PlanSelect',
      viewName: PAGE_NAME,
      properties: {
        planType,
        totalCostInCents
      }
    })

    onPlanSelected({ planType, totalCostInCents })
  }

  return (
    <FullPage
      bodyClassName={styles.container}>

      <TextHeader
        title="One annual plan, two options."
        subtitle="Both provide the same level of care, so pick the one that fits your budget."
        titleClassName={styles.header_title}
        subtitleClassName={styles.header_subtitle}
      />

      {!pricing.plans.length &&
        <ErrorTreatment
          className={styles.error}
          onTryAgainClick={reloadPricing}
          isLoading={isPricingLoading}
        />
      }

      {!!pricing.plans.length &&
        <div className={styles.plans}>
          <PlanItem
            title={`$${centsToDollarsTrunc(annualPlan.totalCostInCents)}/year`}
            description={
              <Coupon
                discountInCents={annualPlan.discountInCents}
                applyCode={applyCouponCode}
              />
            }
            key="plan-annual"
            buttonType={ButtonType.DEFAULT}
            savingsAmount={centsToDollarsTrunc(12 * monthlyPlan.subtotalCostInCents - annualPlan.totalCostInCents)}
            onSelected={() => handlePlanSelect({
              planType: PlanType.ANNUAL,
              totalCostInCents: annualPlan.totalCostInCents
            })}
          />
          <PlanItem
            title={`$${centsToDollarsTrunc(monthlyPlan.totalCostInCents)}/month`}
            description="1-year commitment"
            key="plan-monthly"
            buttonType={ButtonType.TERNARY}
            onSelected={() => handlePlanSelect({
              planType: PlanType.MONTHLY,
              totalCostInCents: monthlyPlan.totalCostInCents
            })}
          />
        </div>
      }
    </FullPage>
  )
}

export default JoinPlanPage
