const tikTokService = {

  recordEvent: ({ eventName, eventDetails }) => {
    if (window.ttq) {
      window.ttq.track(eventName, eventDetails)
    }
  },

  recordAddToCartEvent: ({ eventId }) => {
    tikTokService.recordEvent({
      eventName: 'AddToCart',
      eventDetails: {
        event_id: eventId
      }
    })
  },

  recordPurchaseEvent: ({ eventId }) => {
    tikTokService.recordEvent({
      eventName: 'PlaceAnOrder',
      eventDetails: {
        event_id: eventId
      }
    })
  }
}

export default tikTokService
